"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.loader = exports.Resources = void 0;
const excalibur_1 = require("excalibur");
const plugin_tiled_1 = require("@excaliburjs/plugin-tiled");
const customloader_1 = require("./customloader");
exports.Resources = {
    HeroSpriteSheetPng: new excalibur_1.ImageSource('./img/Hero 01.png'),
    SpawnMap: new plugin_tiled_1.TiledResource('./res/spawn.tmx'),
    InsideMap: new plugin_tiled_1.TiledResource('./res/inside1.tmx'),
    OutsideMap: new plugin_tiled_1.TiledResource('./res/outside.tmx'),
    FinalMap: new plugin_tiled_1.TiledResource('./res/final.tmx'),
    Sprites: []
};
// Dynamically load sprites from ./img/animsprites
// @ts-ignore
const spritesContext = require.context('../img/animsprites', false, /\.(png|jpg|jpeg|gif)$/);
spritesContext.keys().forEach((file) => {
    const sprite = new excalibur_1.ImageSource(spritesContext(file));
    exports.Resources.Sprites.push(sprite);
});
exports.loader = new customloader_1.ReviewLoader();
for (let resource of Object.values(exports.Resources)) {
    if (Array.isArray(resource)) {
        for (let res of resource) {
            exports.loader.addResource(res);
        }
    }
    else {
        exports.loader.addResource(resource);
    }
}
