"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Player = void 0;
const ex = __importStar(require("excalibur"));
const resources_1 = require("./resources");
const config_1 = require("./config");
const spritesContext = require.context('../img/animsprites', false, /\.(png|jpg|jpeg|gif)$/);
let names = spritesContext.keys();
class Player extends ex.Actor {
    constructor(pos, free = false, main = false) {
        var _a, _b;
        super({ pos, width: 1, height: 1, z: 100, collisionType: free ? ex.CollisionType.Active : ex.CollisionType.Passive });
        this.isMain = false;
        this.scale = new ex.Vector(3, 3);
        this.sheetIndex = Math.floor(Math.random() * names.length);
        this.isMain = main;
        this.setSpriteSheet(this.sheetIndex);
        if (this.isMain) {
            (_a = document.getElementById("prevBtn")) === null || _a === void 0 ? void 0 : _a.addEventListener('click', () => this.prevSpriteSheet());
            (_b = document.getElementById("nextBtn")) === null || _b === void 0 ? void 0 : _b.addEventListener('click', () => this.nextSpriteSheet());
        }
    }
    onInitialize(engine) {
        this.createAnimations();
    }
    createAnimations() {
        const anims = [
            { name: 'left-idle', frames: [{ col: 3, row: 0 }] },
            { name: 'right-idle', frames: [{ col: 1, row: 0 }] },
            { name: 'up-idle', frames: [{ col: 0, row: 0 }] },
            { name: 'down-idle', frames: [{ col: 2, row: 0 }] },
            { name: 'left-walk', frames: [{ col: 0, row: 4 }, { col: 1, row: 4 }] },
            { name: 'right-walk', frames: [{ col: 0, row: 2 }, { col: 1, row: 2 }] },
            { name: 'up-walk', frames: [{ col: 0, row: 1 }, { col: 1, row: 1 }] },
            { name: 'down-walk', frames: [{ col: 0, row: 3 }, { col: 1, row: 3 }] }
        ];
        anims.forEach(a => this.graphics.add(a.name, new ex.Animation({
            frames: a.frames.map(f => ({ graphic: this.playerSpriteSheet.getSprite(f.col, f.row), duration: config_1.Config.PlayerFrameSpeed }))
        })));
    }
    setSpriteSheet(index) {
        this.sheetIndex = index;
        this.playerSpriteSheet = ex.SpriteSheet.fromImageSource({
            image: resources_1.Resources.Sprites[this.sheetIndex],
            grid: { spriteWidth: 16, spriteHeight: 16, rows: 5, columns: 4 }
        });
        this.graphics.hide();
        this.createAnimations();
        if (this.isMain)
            document.getElementById("activeCharacter").innerText = names[this.sheetIndex].split("/")[1].split(".")[0];
        this.graphics.use('down-idle');
    }
    get spriteIndex() { return this.sheetIndex; }
    nextSpriteSheet() { this.setSpriteSheet((this.sheetIndex + 1) % resources_1.Resources.Sprites.length); }
    prevSpriteSheet() { this.setSpriteSheet((this.sheetIndex - 1 + resources_1.Resources.Sprites.length) % resources_1.Resources.Sprites.length); }
}
exports.Player = Player;
