"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
class AStarNode {
    constructor(x, y, g = 0, h = 0, parent = null) {
        this.x = x;
        this.y = y;
        this.g = g;
        this.h = h;
        this.parent = parent;
    }
    get f() { return this.g + this.h; }
}
class AStar {
    constructor(grid, stupidityFactor = 0) {
        this.grid = grid;
        this.stupidityFactor = stupidityFactor;
    }
    heuristic(node, goal) {
        const dx = Math.abs(node.x - goal.x), dy = Math.abs(node.y - goal.y);
        return (dx + dy) * (1 + Math.random() * this.stupidityFactor);
    }
    getNeighbors(node) {
        const neighbors = [], dirs = [{ dx: -1, dy: 0 }, { dx: 1, dy: 0 }, { dx: 0, dy: -1 }, { dx: 0, dy: 1 }, { dx: -1, dy: -1 }, { dx: 1, dy: -1 }, { dx: -1, dy: 1 }, { dx: 1, dy: 1 }];
        for (const { dx, dy } of dirs) {
            const x = node.x + dx, y = node.y + dy;
            if (x >= 0 && y >= 0 && y < this.grid.length && x < this.grid[0].length && this.grid[y][x] === 0)
                neighbors.push(new AStarNode(x, y));
        }
        return neighbors;
    }
    interpolatePoints(x1, y1, x2, y2, numPoints, easingFn) {
        const points = [];
        for (let i = 0; i <= numPoints; i++) {
            const t = i / numPoints, x = x1 + (x2 - x1) * easingFn(t), y = y1 + (y2 - y1) * easingFn(t), gx = Math.round(x), gy = Math.round(y);
            if (gx >= 0 && gy >= 0 && gy < this.grid.length && gx < this.grid[0].length && this.grid[gy][gx] === 0)
                points.push({ x, y });
            else
                break;
        }
        return points;
    }
    reconstructSmoothPath(node, points = 10, easing = 'linear') {
        const path = [];
        while (node) {
            if (node.parent)
                path.unshift(...this.interpolatePoints(node.parent.x, node.parent.y, node.x, node.y, points, AStar.EasingFunctions[easing]));
            else
                path.unshift({ x: node.x, y: node.y });
            node = node.parent;
        }
        return path;
    }
    findPath(sx, sy, gx, gy) {
        const open = [new AStarNode(sx, sy)], goal = new AStarNode(gx, gy), closed = [];
        while (open.length) {
            open.sort((a, b) => a.f - b.f);
            const curr = open.shift();
            if (!curr || (curr.x === goal.x && curr.y === goal.y))
                return this.reconstructPath(curr);
            closed.push(curr);
            for (const neighbor of this.getNeighbors(curr)) {
                if (closed.some(n => n.x === neighbor.x && n.y === neighbor.y) || this.grid[neighbor.y][neighbor.x] === 1)
                    continue;
                const tentativeG = curr.g + (Math.abs(neighbor.x - curr.x) === 1 && Math.abs(neighbor.y - curr.y) === 1 ? Math.SQRT2 : 1);
                const openNode = open.find(n => n.x === neighbor.x && n.y === neighbor.y);
                if (!openNode || tentativeG < neighbor.g) {
                    neighbor.g = tentativeG;
                    neighbor.h = this.heuristic(neighbor, goal);
                    neighbor.parent = curr;
                    if (!openNode)
                        open.push(neighbor);
                }
            }
        }
        return null;
    }
    findSmoothPath(sx, sy, gx, gy, points = 10, easing = 'linear') {
        const path = this.findPath(sx, sy, gx, gy);
        return path ? this.reconstructSmoothPath(path[path.length - 1], points, easing) : null;
    }
    reconstructPath(node) {
        const path = [];
        while (node) {
            path.unshift(node);
            node = node.parent;
        }
        return path;
    }
}
AStar.EasingFunctions = { linear: (t) => t, easeInQuad: (t) => t * t, easeOutQuad: (t) => t * (2 - t), easeInOutQuad: (t) => t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t };
exports.default = AStar;
